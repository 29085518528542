import {applySnapshot, types} from "mobx-state-tree";
import ApiCall from "../../utils/fetch";
import Language from "../../lang";

enum WebsiteDetailsLocalStorageVars {
	resources="LOCAL_STORAGE_DOPAMINE_RESOURCES",
	version="LOCAL_STORAGE_DOPAMINE_RESOURCES_VERSION"
}

export enum PartnerType {
	CERTIFICATION=1,
	FUNDER
}

const PartnerModel = types.model({
	image:types.optional(types.maybeNull(types.string),""),
	partner_type:types.optional(types.union(
		types.literal(PartnerType.CERTIFICATION),
		types.literal(PartnerType.FUNDER),
	),PartnerType.CERTIFICATION)
});
const DetailsModel = types.model({
	title:types.optional(types.maybeNull(types.string),""),
	description:types.optional(types.maybeNull(types.string),""),
	primary_color:types.optional(types.maybeNull(types.string),""),
	secondary_color:types.optional(types.maybeNull(types.string),""),
	logo:types.optional(types.maybeNull(types.string),""),
	contact_us_text:types.optional(types.maybeNull(types.string),""),
	about_us_text:types.optional(types.maybeNull(types.string),""),
	terms_text:types.optional(types.maybeNull(types.string),""),
	css_file:types.optional(types.maybeNull(types.string),""),
	who_we_are:types.optional(types.maybeNull(types.string),""),
	who_we_are_bar:types.optional(types.maybeNull(types.string),""),
	dopamine_description:types.optional(types.maybeNull(types.string),""),
	footer_text:types.optional(types.maybeNull(types.string),""),
	social_facebook:types.optional(types.maybeNull(types.string),""),
	social_twitter:types.optional(types.maybeNull(types.string),""),
	social_linkedin:types.optional(types.maybeNull(types.string),""),
	whats_app_uri:types.optional(types.maybeNull(types.string),""),
	youtube_uri:types.optional(types.maybeNull(types.string),""),
	contact_us_description:types.optional(types.maybeNull(types.string),""),
	contact_us_address:types.optional(types.maybeNull(types.string),""),
	contact_us_email:types.optional(types.maybeNull(types.string),""),
	contact_us_phone:types.optional(types.maybeNull(types.string),""),
	contact_us_fax:types.optional(types.maybeNull(types.string),""),
	partners:types.optional(types.array(PartnerModel),[]),
});

const WebsiteDetailsModel = types.model({
	loading:types.optional(types.boolean,true),
	resource_version:types.optional(types.maybeNull(types.string),window.localStorage.getItem(WebsiteDetailsLocalStorageVars.version)),
	details:types.optional(DetailsModel,{})
}).actions((self) => {

	function setItem(type,val){
		self[type] = val;
	}

	function setResource(val){
		applySnapshot(self.details,val);
		window.localStorage.setItem(WebsiteDetailsLocalStorageVars.resources,JSON.stringify(val))
	}

	function setVersion(ver){
		self.resource_version = ver;
		window.localStorage.setItem(WebsiteDetailsLocalStorageVars.version,ver)
	}

	async function checkVersion() {
		let version = window.localStorage.getItem(WebsiteDetailsLocalStorageVars.version);
		let res = window.localStorage.getItem(WebsiteDetailsLocalStorageVars.resources);
		if(version){
			try {
				let response = await ApiCall(`/website/websites/${Language.details.website_type_key}/resource_version/`,{get:true,log:true , justData:true , noError:true , status:200 , error_status:[404,401,400] });
				if(response){
					if(version === response.version){
						this.setResource(JSON.parse(res));
						this.setItem("loading",false);
					}else{
						this.loadResources();
					}
				}
			}catch (e) {
				this.checkVersion();
			}
		}else{
			this.loadResources();
		}
	}

	async function loadResources() {
		try {
			let response = await ApiCall(`/website/websites/${Language.details.website_type_key}/resource/`,{get:true,log:true , justData:true , noError:true , status:200 , error_status:[404,401,400] });
			if(response){
				this.setResource(response.data);
				this.setVersion(response.version);
				this.setItem("loading",false);
			}
		}catch (e) {
			this.loadResources();
		}

	}


	return {
		setItem,
		checkVersion,
		setResource,
		setVersion,
		loadResources
	}
})



export default WebsiteDetailsModel;