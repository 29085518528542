import React, { useRef } from 'react'
import styled, { withTheme } from 'styled-components';
import { DivTypes } from 'types';

type AuthInputProps = {
    icon:any,
    place:string
}

function AuthInput(props:any) {
    const {theme} = props;
    const inputRef = useRef<any>(null);

    const press = () => inputRef.current.focus();

    const onChange = (value) => {
        if(props.onChange) props.onChange(value)
    }

    return (
        <Wrap onClick={press} error={props.error} className="input-wrap">
            <IconWrapper>
                <Icon src={props.icon} />
            </IconWrapper>
            <Input 
                type={props.type}
                ref={inputRef}
                onChange={e => onChange(e.target.value)}
                placeholder={props.place} />
        </Wrap>
    )
}

type WrapType = DivTypes & {error?:boolean}

const Wrap = styled.div<WrapType>`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    height:60px;
    background:${p => p.theme.colors.white_four};
    margin:18px 0;
    border-radius:30px;
    box-shadow:0 1.5px 7.5px 0 rgba(0, 0, 0, 0.2);
    cursor:pointer;
    box-shadow:0 5px 10px 0 ${p => p.error ? 'rgba(255, 0, 0, 0.1)' : 'transparent'} ;
    border:${p => p.error ? '1px' : '0px'} solid rgba(255, 0, 0, 0.15);
`;
const Input = styled.input<DivTypes>`
    height:90%;
    border:none;
    width:100%;
    border-radius:0 30px 30px 0;
    outline: none;
    padding: 10px;
    font-size:11pt;
    background:none;
`;

const IconWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:15%;
    height:100%;
    border-right:1px solid #cfcfcf;
`;
const Icon = styled.img`
    width:20px;
    height:20px;
`;

export default withTheme(AuthInput);