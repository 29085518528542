import React from 'react';
import styled from 'styled-components';
import { DivTypes , SizeTypes, ReactTypes, TextTypes } from "types";
import Text from 'styles/Text';
import LoadingSvg from 'assets/svg/loading';
import rolling_white from 'assets/svg/rolling_white.svg'

interface Buttonype extends DivTypes , SizeTypes , TextTypes {}
export type iButton = ReactTypes & SizeTypes & DivTypes & TextTypes & {loading?:boolean;};

function Button(props:iButton) {
    return (
        <ButtonWrapp {...props} className={props.classes}>
            {props.loading ? 
                <img src={rolling_white} style={{width:'20px',height:'20px'}}/>
            : 
            <Text cursor={props.disable ? 'default' : "pointer"} hoverTextColor={props.hoverTextColor} color={props.color || 'white'} size={props.size} weight={props.weight}>{props.children}</Text>
            }
        </ButtonWrapp>
    )
}

const ButtonWrapp = styled.div<Buttonype>`
    border:2px solid ${p => p.borderColor || p.theme.colors.default};
    padding:${p => p.padding || '0'};
    margin:${p => p.margin || '0'};
    background: ${p => p.bgColor || 'transparent'};
    width:${p => p.width};
    height:${p => p.height};
    border-radius:${p => p.radius || '0px'};
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:${p => p.disable ? 'default' : 'pointer'};

    &:hover {
        background: ${p => p.hoverBgColor || p.bgColor || 'transparent'};
    }

    &:hover > ${Text} {
        color: ${p => p.hoverTextColor || p.color || 'white'};
    }
`;


export default Button;