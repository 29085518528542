import { SnapshotIn, Instance } from "mobx-state-tree";
import { QuestionModel, AnswerModel } from "./FormModel";
import { RootModel } from ".";

// form type
export type RootInstance = Instance<typeof RootModel>;
export interface IQuestionModel extends SnapshotIn<typeof QuestionModel> {}
export interface IAnswerModel extends SnapshotIn<typeof AnswerModel> {}

export enum QUESTION_TYPE {
    TEXT = 1,
    RADIO = 2,
    CHECKNOX = 3
}
