import React , {useContext} from 'react';
import storesContext from '../models';
import { RootInstance } from 'store/models/types';

function useStores() : RootInstance {
  const store = useContext(storesContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return useContext(storesContext)
}

export default useStores;