import { ERROR_CODES } from "../utils/fetch";
import Language from 'lang';

function error_text(code) {
    if(code === ERROR_CODES.UNIQUE) return  Language.unique;
    if(code === ERROR_CODES.EMPTY) return  Language.empty;
    if(code === ERROR_CODES.INVALID) return  Language.invlaid;
    if(code === ERROR_CODES.MIN_LENGTH) return  Language.min_length;
}

export function ValidationError(errors:object = {}){
    let callbackErrors = [];
    const vErrors = Object.keys(errors);
    console.log("vErrors => ",vErrors)
    for(let e of vErrors){
        if(errors[e]) {
            console.log("errors[e] => ",errors[e])
            errors[e].forEach((newError) => {
                console.log("newError => ",newError)
                if(newError.code) callbackErrors.push({name:e !== "non_field_errors" ? e : '',error:error_text(newError.code)})
            });
        }else{
            callbackErrors = [...callbackErrors , ...ValidationError(errors[e]) ]
        }
    }
    return callbackErrors;
}
