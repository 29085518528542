import axios from 'axios';
import yn from 'yn'
import Language, { APP_API } from 'lang';
import {state} from 'jetstate'
import Logout from "../helper/logout";
import VisitorId from 'utils/visitorId';

export const ERROR_CODES = {
    INVALID:'invalid',
    MIN_LENGTH:'min_length',
    EMPTY:'empty',
    UNIQUE:'unique'
}

function connection_error(msgText = Language.errors.fetch){
    alert(msgText);
}

// Check responseStatus (int) and whiteStatus (int or array) is same 
function isStstusCorrect(responseStatus , whiteStatus){
    return (typeof whiteStatus === "number" && responseStatus === whiteStatus) 
    ||
    (typeof whiteStatus === "object" && whiteStatus.indexOf(responseStatus) !== -1) 
}

// Fetch Data from api
const ApiCall = async ( action = '' , options = {} ) => {
    const { log , method , get , post , noToken , status , force_token , hardLog , data , justData , justStatus , noError , token_schema , error_status } = options;
    
    // set request method
    let req_method = method || 'post'
    req_method = get ? 'get' : req_method;
    req_method = post ? 'post' : req_method;

    // set token
    /*
     * define token
     * default empty
     * 
     * if debug is true (.env) sets to test_token (.env)
     * 
     * if debug is false and state.token is defined sets to state.token
     * 
     * if force_token defines token sets to force_token
     * ApiCall('{somePage}',{force_token:'MY LOVELY FORCE TOKEN'})
    */ 
    let token = '';
    if(!noToken){
        // if(yn(process.env.REACT_APP_debug)) {
        //     token = process.env.REACT_APP_test_token;
        // }else{
            if(state.user) token = state.user.token;
            token = force_token || token;
        // }
    }
    
    /*
     * set token schema
     * default JWT
     * ApiCall('{somePage}',{status:200})
    */ 
    let tSchema = "Bearer";
    if(token_schema) tSchema = token_schema;

    // config url
    let AppHost = APP_API;
    
    const URL = (action.startsWith("http")) ? action : AppHost + action;

    /*
     * acceptable response
     * ApiCall('{somePage}',{status:200})
     * ApiCall('{somePage}',{status:[200,400,404]})
    */ 
    const accept_response_status = status || 200; 

    return new Promise(async (resolve) => {
        const formData = new FormData();

        /*
        * set body
        * ApiCall('{somePage}',{data: {name:'mohammad',age:'19'} })
        */ 
        if(options.data){
            await Promise.all(Object.keys(options.data).map(async (e) => {
                    formData.append(e, options.data[e]);
            }));
        }

        /*
        * upload file(s)
        * ApiCall('{somePage}',{files: {avatar:'absolute uri path from fetch_blob uri'} })
        */
        if(options.files){
            await Promise.all(Object.keys(options.files).map(async (e) => {
                if(options.files[e]) formData.append(e, options.files[e]);
            }));
        }

        /*
        * log before send request
        * ApiCall('{somePage}',{ log:true })
        */
        if(log || hardLog) console.log("Request ",req_method,URL," => ",data ? (justData ? data : formData) : null);

        let headers = {
            'X-RESOLUTION':`${window.screen.width * window.devicePixelRatio}x${window.screen.height * window.devicePixelRatio}`,
            'X-VISITOR-ID':VisitorId()
        };

        if(token) headers.Authorization = tSchema+" "+token;
        
        axios({
            method:req_method,
            url:URL,
            headers,
            data:data ? (justData ? data : formData) : null
        }).then(response => {

            /*
            * log response
            * ApiCall('{somePage}',{ hardLog:true })
            */
            if(hardLog) console.log(response);

            // check status is correct
            if(isStstusCorrect(response.status , accept_response_status)){
                if(log || hardLog) console.log("Response JSON",URL," => ",response.data);

                /*
                * resolve true if there is no need to return response json
                * ApiCall('{somePage}',{ status:201 , justStatus:true })
                */
                if(justStatus) return resolve(true);

                /*
                * check json data of response and return it
                * const r = await ApiCall('{somePage}');
                * console.log(r.name)
                */
                if(response.data){
                    if(response.data.hasError){
                        if(response.data.onError){
                            options.onError(response.message);
                        }else if(response.data.message) {
                            connection_error(response.data.message)
                        }
                        resolve(false);
                        return false;
                    }
                    resolve(response.data);
                }
            }else{
                /*
                * if response status is in 200 range but it's not our status
                */
                console.warn(`Response status is ${response.status} and it's not as same as ${accept_response_status}`);
                connection_error();
                resolve(false);
            }
        }).catch(async (e) => {
            /*
            * skip show error alert
            * ApiCall('{somePage}',{ noError:true })
            */
            // if(!noError) connection_error();

            /*
             * check for error status
             * error_status?: number | number[]
            */ 
            if(error_status && e.response && e.response.status && ( (typeof error_status === "number" && error_status == e.response.status ) || (typeof error_status === "object" && isStstusCorrect(e.response.status , error_status) ) )){
                    return resolve({
                        hasError:true,
                        status:e.response.status,
                        data:e.response.data
                    });
            }

            /*
             * check for acceptable status in out of 200 range
            */ 
            const wrnogStatus = e.toString().startsWith("Error: Request failed with status code") ? e.response.status : 0
            if(wrnogStatus && isStstusCorrect(wrnogStatus , accept_response_status)){
                return resolve(e.response.status);
            }

            // log and resolve false
            if(log || hardLog) console.warn(e , e.response)

            if(e.response){
                if(e.response.status === 500){
                    connection_error(Language.error_500);
                    return;
                }
                if(e.response.status === 502){
                    connection_error(Language.error_502);
                    return;
                }
                if(e.response.status === 401){
                    Logout();
                    connection_error(Language.messages.signout)
                    return;
                }
                if(e.response.status === 403){
                    connection_error()
                    return;
                }
                if(e.response.status === 400){
                    console.info("400 input or logic error")
                    connection_error()
                    return;
                }
            }

            return resolve(false);
        });
    });

}

export default ApiCall;