
import React from 'react'
import {IconTypes} from 'types'
export default function LinkedinIcon(props:IconTypes) {
    return (
        <svg style={props.style} xmlns="http://www.w3.org/2000/svg" width={props.width || "40"} height={props.height || "40"} viewBox="0 0 34.194 34.194">
            <path id="linkedin" d="M17.1 0a17.1 17.1 0 1 0 17.1 17.1A17.1 17.1 0 0 0 17.1 0zm-4.971 25.846H7.965V13.319h4.164zm-2.082-14.238h-.027a2.17 2.17 0 1 1 .055-4.328 2.171 2.171 0 1 1-.028 4.328zm17.1 14.238h-4.168v-6.7c0-1.684-.6-2.833-2.109-2.833a2.279 2.279 0 0 0-2.136 1.523 2.851 2.851 0 0 0-.134 1.014v7h-4.167s.055-11.352 0-12.527H18.6v1.774a4.134 4.134 0 0 1 3.752-2.068c2.739 0 4.794 1.79 4.794 5.638zm0 0" className="svg-logo svg-fill-default" style={props.pathStyle}/>
        </svg>
    )
}
