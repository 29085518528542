import styled from "styled-components";
import { ReactTypes, DivTypes, FlexTypes, SizeTypes } from "types";

export interface RowType extends FlexTypes , ReactTypes , DivTypes , SizeTypes {}

const Row = styled.div<RowType>`
    width:${p => p.width || 'auto'};
    height:${p => p.height || 'auto'};
    display:flex;
    padding:${(p) => p.padding || '0'};
    justify-content:${(p) => p.jc || 'center'};
    align-items:${(p) => p.ai || 'center'};
    background:${(p) => p.bgColor || "transparent"};
    flex-direction:${p => p.dir || 'row'};
    border-radius:${p => p.radius || 0};
    box-shadow:${p => p.boxShadow || 'none'};
    margin:${p => p.margin || 0};
    flex-wrap:${p => p.wrap || 'nowrap'};
    position:${p => p.position || 'static'};
    cursor:${p => p.cursor || 'auto'};
`;

export default Row;