import React, { useState, useEffect , useRef } from 'react'
import { withRouter } from 'react-router';
import { on , unsubscribeOf } from 'jetemit'
import styled from 'styled-components';
import Text from 'styles/Text';
import BottomSvg from 'assets/svg/sing_in.svg';
import ExitSvg from 'assets/svg/exit.svg';
import Language from 'lang';
import AuthInput from '../components/AuthInput';
import UsernameIcon from 'assets/svg/switch_account.svg'
import PasswordIcon from 'assets/svg/vpn_key.svg'
import LoadingSpinner from 'assets/svg/vpn_key.svg'
import Row from 'styles/Row';
import AUTH_KEYS from 'vars/auth';
import ROUTE from 'vars/router';
import LoadingSvg from 'assets/svg/loading';
import useLoginLogic from './hooks/useLoginLogic';
import useForgotLogic from './hooks/useForgotLogic';
import Login from './Login';
import Forgot from './Forgot';

function PersonCard(props:any) {
    
    const [show, setShow] = useState(false);
    const [forgot, setForgot] = useState(false);
    const [showVerify, setShowVerify] = useState(null);

    const wrapperRef = useRef(null);

    const { resetLogin } = useLoginLogic();
    const { resetForgot } = useForgotLogic();

    const toggleShow = () => setShow(show => !show);

    useEffect(() => {

        // event listener for open auth modal
        on(AUTH_KEYS.TOGGLE_AUTH_MODAL , (e) => {
            setForgot(false)
            resetForgot();
            resetLogin();
            toggleShow();
            setShowVerify(null);
            if(e && e.verify){
                setShowVerify(e.verify)
            }
        });

        // handle click outside for close popup
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            unsubscribeOf(AUTH_KEYS.TOGGLE_AUTH_MODAL);
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, []);

    const handleClickOutside = (event:any) => {
        if( wrapperRef.current && !wrapperRef.current.contains(event.target) ) {
            setShow(false);
        }  
    }

    const goToSignup = () => {
        toggleShow();
        props.history.push(ROUTE.AUTH_SIGN_UP);
    }
    const forgotShow = () => setForgot(true);

    if(!show) return null;

    return (
        <Container className="content">
            <Wrap ref={wrapperRef} className="alert-modal">
                <CloseButton src={ExitSvg} onClick={toggleShow} />
                <FooterImage className="alert-modal-bottom-image"/>
                <Text letterSpacing="1px" size="20pt" colorFromTheme="text" align="center" margin="30px 0 15px 0" weight="200" className="title">{Language.auth.welcome}</Text>
                <br /><br />
                <ContentWrapper className="alert-modal-content">

                    {forgot ? 
                    <Forgot />
                    :
                    <Login defaultShowVerify={showVerify} onForgotRequest={forgotShow} />
                    }

                </ContentWrapper>

                <Row width="100%" style={{position:'absolute',bottom:20}}>
                    <Text size="12pt" color="white" align="center">{Language.auth.dont_have}</Text> &nbsp;
                    <Text size="12pt" color="white" align="center" decoration="underline" cursor="pointer" weight="bold" onClick={goToSignup}>{Language.auth.sign_up_now}</Text>
                </Row>
            </Wrap>
        </Container>
    )
}

const Container = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background:rgba(0,0,0,0.6);
    z-index:9;
`;

const Wrap = styled.div`
    width:500px;
    height:600px;
    padding:20px;
    background:white;
    border-radius:20px;
    box-shadow:0 5px 12.5px 0 rgba(0, 0, 0, 0.44);
    position: relative;
    display:flex;
    align-items:center;
    flex-direction:column;
`;

const FooterImage = styled.div`
    width:100%;
    height:200px;
    background:url(${BottomSvg});
    position:absolute;
    bottom:0;
    border-radius:0 0 20px 20px;
`;
const ContentWrapper = styled.div`
    position:relative;
    margin-top:30px;
    width:80%;
`;
const LoadingWrap = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
`;

const CloseButton = styled.img`
    width:40px;
    height:40px;
    cursor:pointer;
    position:absolute;
    left:15px;
    top:15px;
`;

const Button = styled.div`
    width:100%;
    height:50px;
    display:flex;
    justify-content:center;
    align-items:center;
    background:${p => p.theme.colors.gunmetal_two};
    border-radius:20px;
    cursor:pointer;
`;

export default withRouter(PersonCard);