import React , {createContext} from 'react'
import { types, applySnapshot } from "mobx-state-tree";
import FormModel from './FormModel';
import WebsiteDetails from './WebsiteDetails';
import { RootInstance } from './types';

const RootModel = types.model({
    form:types.optional(FormModel,{}),
    website_details:types.optional(WebsiteDetails,{})
})
.actions((self) => {
    function resetFormStore(){
        applySnapshot(self.form,{})
    }

    return {
        resetFormStore
    }
});

const rootStore = RootModel.create({
    form:{},
    website_details:{}
});


const storesContext = createContext<null | RootInstance>(rootStore);

export default storesContext;
export {
    RootModel
}