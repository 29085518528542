import { useState } from 'react';
import { forogt , IErrorForgot , initialErrorsForgot } from '../model'
import ApiCall from 'utils/fetch';
import { ValidationError } from 'helper/fetchValidationError';
import { setUser } from 'helper/user';
import Language from 'lang';
import AUTH_KEYS from 'vars/auth';
import { useAlert } from 'react-alert'
import {emit} from 'jetemit'

export default () => {
    const [forgotLoading , setLoading] = useState(false);
    const [forgotGetVerifyCode , setGetVerifyCode] = useState(false);
    const [forgotErrors, setErrors] = useState<IErrorForgot>(initialErrorsForgot)
    const [forgotFetchErrors, setFetchErrors] = useState<object[]|any>([])
    const alert = useAlert();
    function validate(name:any , text:any , justReturn:boolean = false){
        const validate_errors:any = {};
        if(justReturn) return validate_errors;
        setErrors({...forgotErrors,[name]:typeof text === "string" && text === "" ? true : false,...validate_errors});
    }

    const onChange = (name: any , options:any = {}) => (text: any) => {
        let value = text.nativeEvent ? text.nativeEvent.text.toLowerCase() : text;
        // validate
        if(options.validate) validate(options.sub ? options.sub : name , value , false);

        forogt[name] = value;
    }

    async function submitForgot(){
        if(forgotLoading) return;
        if(Object.values(forgotErrors).every(e => !e)){
            setFetchErrors([]);
            setLoading(true);
            if(forgotGetVerifyCode){
                let response = await ApiCall("/user/createnewuser/verify_reset_password/",{post:true,log:true,data:forogt , justData:true , noError:true , status:201 , error_status:[404,401,400] });
                if(response.token) {
                    setGetVerifyCode(false);
                    setUser(response);
                }
            }else{
                let response = await ApiCall("/user/createnewuser/reset_password/",{post:true,log:true,data:forogt , justData:true , noError:true , status:201 , error_status:[404,401,400] });
                console.log("res => ", response)
                if(response && response.hasError){
                    if(response.data.error || response.data.message){
                        setFetchErrors([{name:"",error:response.data.error || response.data.message}]);
                    }else{
                        setFetchErrors(ValidationError(response.data));
                    }
                }else if(response && !response.hasError){
                    alert.success(Language.auth.reset_link);
                    emit(AUTH_KEYS.TOGGLE_AUTH_MODAL);
                }
            }
            
            setLoading(false);
        }
    }

    function resetForgot(){
        setErrors(initialErrorsForgot)
        setGetVerifyCode(false);
        setFetchErrors([]);
        setLoading(false);
    }

    return {
        onChange,
        submitForgot,
        forgotLoading,
        forgotErrors,
        forgotFetchErrors,
        forgotGetVerifyCode,
        resetForgot
    }
}