import React, { useState, useEffect , useRef } from 'react'
import { withRouter } from 'react-router';
import { on , unsubscribeOf } from 'jetemit'
import styled from 'styled-components';
import Text from 'styles/Text';
import BottomSvg from 'assets/svg/sing_in.svg';
import ExitSvg from 'assets/svg/exit.svg';
import Language from 'lang';
import Row from 'styles/Row';
import { TOGGLE_ALERT_MODAL } from 'vars/alert';

function AlertModal(props:any) {
    const [show, setShow] = useState(false);
    const [options, setOptions] = useState<any>({})
    const wrapperRef = useRef(null);
    const toggleShow = (opt:any = null) => {
        if(opt) setOptions(opt);
        setShow(show => !show);
    }

    useEffect(() => {

        // event listener for open auth modal
        on(TOGGLE_ALERT_MODAL , (opt = null) => {
            toggleShow(opt);
        });

        // handle click outside for close popup
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            unsubscribeOf(TOGGLE_ALERT_MODAL);
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, []);

    const handleClickOutside = (event:any) => {
        if( wrapperRef.current && !wrapperRef.current.contains(event.target) ) {
            setShow(false);
        }  
    }

    function onButtonClick(){
        toggleShow();
        if(options.onButtonClick) options.onButtonClick();
    }

    if(!show) return null;

    return (
        <Container>
            <Wrap ref={wrapperRef} className="alert-modal">
                <Text size="16pt" className="title" weight="bold" colorFromTheme="text" align="center" style={{position:'absolute',top:20,left:0,right:0}}>{options.title}</Text>
                <CloseButton src={ExitSvg} onClick={toggleShow} />
                <FooterImage className="alert-modal-bottom-image"/>
                <ContentWrapper className="alert-modal-content">
                    <Text size="12pt" colorFromTheme="text" align="center" className="content">{options.content}</Text>
                </ContentWrapper>
                <ContentWrapper style={{position:'absolute',bottom:20}}>
                    {options.button ?
                    <Button onClick={onButtonClick}>
                        <Text cursor="pointer" size="15pt" color="white" align="center" weight="bold" className="title">{options.button}</Text>
                    </Button> 
                    : null}
                </ContentWrapper>
            </Wrap>
        </Container>
    )
}

const Container = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background:rgba(0,0,0,0.6);
    z-index:8;
`;

const Wrap = styled.div`
    width:500px;
    min-height:400px;
    padding:20px;
    background:white;
    border-radius:20px;
    box-shadow:0 5px 12.5px 0 rgba(0, 0, 0, 0.44);
    position: relative;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
`;

const FooterImage = styled.div`
    width:100%;
    height:120px;
    background:url(${BottomSvg});
    position:absolute;
    bottom:0;
    border-radius:0 0 20px 20px;
`;
const ContentWrapper = styled.div`
    position:relative;
    margin-top:30px;
    width:80%;
`;
const CloseButton = styled.img`
    width:40px;
    height:40px;
    cursor:pointer;
    position:absolute;
    left:15px;
    top:15px;
`;

const Button = styled.div`
    width:100%;
    height:50px;
    display:flex;
    justify-content:center;
    align-items:center;
    background:${p => p.theme.colors.gunmetal_two};
    border-radius:20px;
    cursor:pointer;
`;

export default withRouter(AlertModal);