import React from 'react'
import { withRouter } from 'react-router';
import Text from 'styles/Text';
import Language from 'lang';
import AuthInput from '../components/AuthInput';
import UsernameIcon from 'assets/svg/switch_account.svg'
import PasswordIcon from 'assets/svg/vpn_key.svg'
import Row from 'styles/Row';
import LoadingSvg from 'assets/svg/loading';
import { LoadingWrap, Button } from './styled';
import useForgotLogic from './hooks/useForgotLogic';

function PersonCard(props:any) {
    
    const { forgotErrors, forgotFetchErrors , forgotGetVerifyCode , forgotLoading , onChange , submitForgot } = useForgotLogic();
    
    return (
        <>

            {forgotGetVerifyCode ? 
            <>
                <Text cursor="pointer" size="12pt" colorFromTheme="text" align="center" margin="10px">{Language.auth.verification_code}</Text>
                <AuthInput key="password" place={Language.auth.new_password} icon={PasswordIcon} onChange={onChange('password',{validate:true})} error={forgotErrors.password} type="password" />
                <AuthInput key="code" place={Language.auth.Verify} icon={PasswordIcon} onChange={onChange('code',{validate:true})} error={forgotErrors.code}/>
            </>
            :
            <>
                <AuthInput key="username" place={Language.auth.email} icon={UsernameIcon} onChange={onChange('email',{validate:true})} error={forgotErrors.email}/>
            </>
            }


            {forgotLoading ?
                <LoadingWrap>
                    <LoadingSvg width="50px" height="50px"/>
                </LoadingWrap>
            :
            <Button onClick={submitForgot}>
                <Text cursor="pointer" size="15pt" color="white" align="center" weight="bold">{forgotGetVerifyCode ? Language.auth.change_password : Language.auth.request}</Text>
            </Button> }

            {forgotFetchErrors.length > 0 ?
            <Row dir="column" width="100%" jc="space-between" ai="flex-start" padding="20px" margin="20px 0" bgColor="rgba(255,0,0,1)" radius="4px" boxShadow="0 5px 10px 0 rgba(255, 0, 0, 0.05)">
                {forgotFetchErrors.map((e , eIndex) => {
                    return <Text size="11pt" margin="5px" color="white" key={String(eIndex)} ><b>{e.name}</b> {e.error}</Text>
                })}
            </Row>
            : null}
        </>
                
    )
}

export default withRouter(PersonCard);