import React, { useState , useCallback } from 'react'
import styled from 'styled-components';
import { NavLink, useHistory } from 'react-router-dom';
import AUTH_KEYS from 'vars/auth';
import {emit} from 'jetemit'

type SideMenuItemType = {
    item:any,
    onPress?:any
}

export default function SideMenuItem(p:SideMenuItemType) {
    const [subMenu, setSubMenu] = useState(false);
    const {item} = p;
    let history = useHistory();
    let hasSub = item.child.length;

    const onClick = (isSub = false , link = '') => () => {
        if(hasSub && !isSub){
            setSubMenu((subMenu) => !subMenu);
        }else{
            if(p.onPress) p.onPress();
            if(link === AUTH_KEYS.TOGGLE_AUTH_MODAL) emit(AUTH_KEYS.TOGGLE_AUTH_MODAL)
        }
    }

    return (
        <ListItemMenu onClick={onClick(false)}>
            {hasSub ? 
                <NavLinkWrapMenuDiv>
                    {item.name}
                </NavLinkWrapMenuDiv>
            : 
                <NavLinkWrapMenu
                    to={item.link}>
                    {item.name}
                </NavLinkWrapMenu>
            }

            {hasSub ?
            <>
                <div className={`sub-menu-burger ${subMenu ? 'active' : ''}`}>
                    {
                        item.child.map(
                            (child, childIndex) => {
                                if(child.link === "/articles") return null; // TODO remove it
                                return (
                                    <NavLink
                                        onClick={onClick(true,child.link)}
                                        key={String(childIndex)}
                                        to={child.link === AUTH_KEYS.TOGGLE_AUTH_MODAL ? history.location.pathname : child.link}>
                                        {child.name}
                                    </NavLink>  
                                )
                            }
                        )
                    }
                </div>
                <div className="sub-menu-icon">{subMenu ? '-' : '+'}</div>
            </>
            : null}

            
        </ListItemMenu>
    )
}

const ListItemMenu = styled.li`
    list-style: none;
    position: relative;
    padding: 15px 0;
    display:flex;
    flex-direction: column;
`;


const NavLinkWrapMenu = styled(NavLink)`
    width:100%;
    height:100%;
    text-decoration:none;
    color:${p => p.theme.colors.text};
    font-weight:bold;
    font-size:11pt;
    padding:0 10px;
`;
const NavLinkWrapMenuDiv = styled.div`
    width:100%;
    height:100%;
    text-decoration:none;
    color:${p => p.theme.colors.text};
    font-weight:bold;
    font-size:11pt;
    padding:0 10px;
    cursor: pointer;
`;
