import React , {useEffect} from 'react'
import { withRouter } from 'react-router';
import Text from 'styles/Text';
import Language from 'lang';
import AuthInput from '../components/AuthInput';
import UsernameIcon from 'assets/svg/switch_account.svg'
import PasswordIcon from 'assets/svg/vpn_key.svg'
import Row from 'styles/Row';
import LoadingSvg from 'assets/svg/loading';
import useLoginLogic from './hooks/useLoginLogic';
import { LoadingWrap, Button } from './styled';

function PersonCard(props:any) {
    
    const { errors ,setErrors, fetchErrors , loading , onChange , submitLogin , getVerifyCode , setGetVerifyCode } = useLoginLogic();
    
    useEffect(() => {
        if(props && props.defaultShowVerify){
            // show verify code page & autofill details from signup
            // TODOOOOO: PLEASE REFACTOR THIS AND USE global state as ASAP!!!!!!!
            setGetVerifyCode(true);
            onChange('email')(props.defaultShowVerify.email as string)
            onChange('password')(props.defaultShowVerify.password)
            setErrors({...errors,email:false,password:false})
        }
        return () => {
            
        }
    }, [props.defaultShowVerify])

    return (
        <>

            {getVerifyCode ? 
            <>
                <Text key="code-text" cursor="pointer" size="12pt" colorFromTheme="text" align="center" margin="10px">{Language.auth.verification_code}</Text>
                <AuthInput key="code" place={Language.auth.Verify} icon={PasswordIcon} onChange={onChange('code',{validate:true})} error={errors.code}/>
            </>
            :
            <>
                <AuthInput key="username" place={Language.auth.username_placeholder} icon={UsernameIcon} onChange={onChange('email',{validate:true})} error={errors.email}/>
                <AuthInput key="password" place={Language.auth.password_placeholder} icon={PasswordIcon} onChange={onChange('password',{validate:true})} error={errors.password} type="password" />
                <Text cursor='pointer' size="10pt" colorFromTheme="text" align="right" margin="15px 0" onClick={props.onForgotRequest}>{Language.auth.forgot}</Text>
            </>
            }            

            {loading ?
                <LoadingWrap>
                    <LoadingSvg width="50px" height="50px"/>
                </LoadingWrap>
            :
            <Button onClick={submitLogin}>
                <Text cursor="pointer" size="15pt" color="white" align="center" weight="bold">{Language.auth.sign_in}</Text>
            </Button> }

            {fetchErrors.length > 0 ?
            <Row dir="column" width="100%" jc="space-between" ai="flex-start" padding="20px" margin="20px 0" bgColor="rgba(255,0,0,1)" radius="4px" boxShadow="0 5px 10px 0 rgba(255, 0, 0, 0.05)">
                {fetchErrors.map((e , eIndex) => {
                    return <Text size="11pt" margin="5px" color="white" key={String(eIndex)} ><b>{e.name}</b> {e.error}</Text>
                })}
            </Row>
            : null}
        </>
                
    )
}

export default withRouter(PersonCard);