import AFMLGrand from '../site/AFMLGrand.json'
import AFML from '../site/AFML.json'
import Tamari06 from '../site/Tamari06.json'
import VFL from '../site/VFL.json'
import Errors from './errors.json'
import Commons from './common.json'

const hostname = document.location.hostname.replace("www.","");
const PROD_HOST = process.env.REACT_APP_HOST;
const DEV_HOST = process.env.REACT_APP_DEV_HOST;

const SitesData = {
    "afml-gs.org":{
        lang:AFMLGrand,
        api:PROD_HOST
    },
    "afmlgs-dev.cosmopolis.agency":{
        lang:AFMLGrand,
        api:DEV_HOST
    },
    "lafml.org":{
        lang:AFML,
        api:PROD_HOST
    },
    "afml-dev.cosmopolis.agency":{
        lang:AFML,
        api:DEV_HOST
    },
    "tamari06.org":{
        lang:Tamari06,
        api:PROD_HOST
    },
    "tamari-dev.cosmopolis.agency":{
        lang:Tamari06,
        api:DEV_HOST
    },
    "vfl-formation.com":{
        lang:VFL,
        api:PROD_HOST
    },
    "vfl-dev.cosmopolis.agency":{
        lang:VFL,
        api:DEV_HOST
    }
}

function Lang() : (typeof AFMLGrand | typeof AFML | typeof Tamari06 | typeof VFL) & typeof Errors & typeof Commons {
    const site = SitesData[hostname] ? SitesData[hostname].lang : VFL;
    return {
        ...site,
        ...Errors,
        ...Commons
    }
}

export default Lang();
export const APP_API = SitesData[hostname] ? SitesData[hostname].api : DEV_HOST //PROD_HOST //  DEV_HOST