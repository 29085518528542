import React, { useEffect } from 'react'
import Base from 'styles/Base'
import Center , {CenterType} from 'styles/Center'
import DopamineImage from 'assets/images/dopamine.jpg'
import NavPattern from 'assets/images/pattern.jpg'
import styled from 'styled-components'
import Button from 'components/Button'
import FacebookIcon from 'assets/svg/facebook'
import LinkedinIcon from 'assets/svg/linkedin'
import WhatsappIcon from 'assets/svg/whatsapp'
import YoutubeIcon from 'assets/svg/youtube'
import TwitterIcon from 'assets/svg/twitter'
import MenuIcon from 'assets/svg/menu.svg'
import AUTH_KEYS from 'vars/auth'
import { NavLink, useHistory } from 'react-router-dom'
import { themeConfigs } from 'App'
import { Sticky } from 'react-sticky';
import Language from 'lang'
import { emit , on } from 'jetemit'
import {state} from 'jetstate'
import useForceUpdate from 'helper/useForceUpdate'
import Text from 'styles/Text'
import Logout from 'helper/logout'
import ROUTE from 'vars/router'
import ApiCall from 'utils/fetch'
import { setUser } from 'helper/user'
import { useAlert } from 'react-alert'
import UserName from "../../utils/userName";
import useStores from "../../store/hooks/useStores";
import getValue from "../../utils/getValue";

export const NavHeight = 80;
type headerType = {

}

function Header(props:headerType) {
    let history = useHistory();
    const { website_details } = useStores();

    const openModal = () => emit(AUTH_KEYS.TOGGLE_AUTH_MODAL);
    const openSideMenu = () => emit(ROUTE.SIDE_MENU);
    const logout = () => {
        Logout();
        alert.success(Language.messages.signout,{timeout: 3000});
    }
    const userCp = () => history.push(ROUTE.USER_SPACE);
    const goHome = () => history.push("/")
    const forceUpdate = useForceUpdate();
    const alert = useAlert();

    async function checkUser(){
        const s = await ApiCall(`/user/user-info/me/`,{get:true,log:true,error_status:401,noError:true,useToken:true,status:[200]});
        if(s){
            if(s.hasError){
                Logout();
                alert.success(Language.messages.signout,{timeout: 3000});
            }else{
                setUser({token:state.user.token , user:s},false);
            }
        }
    }

    useEffect(() => {

        // event listener for logged in
        on(AUTH_KEYS.USER_UPDATE , () => forceUpdate());

        const auth = window.localStorage.getItem(AUTH_KEYS.USER_LOCALSTROAGE_NAME);
        if(auth){
            state.user = JSON.parse(auth);
            checkUser();
        }

        return () => null

    }, []);


    const onNavClick = (link:string) => () => {
        if(link === AUTH_KEYS.TOGGLE_AUTH_MODAL) openModal()
    }

    const iconStyle = {
        style:{
            margin:"10px"
        },
        pathStyle:{
            fill:themeConfigs.colors.primary
        },
        width:"25",
        height:"25"
    }
    const youTubeIconStyle = {
        ...iconStyle,
        width:"30",
        height:"30"
    }

    const activeNavLink = {
        activeStyle:{
            color:process.env.REACT_APP_default
        }
    }

    const socials = {
        facebook:getValue(website_details.details.social_facebook),
        twitter:getValue(website_details.details.social_twitter),
        linkedIn:getValue(website_details.details.social_linkedin),
        whatsapp:getValue(website_details.details.whats_app_uri),
        youtube:getValue(website_details.details.youtube_uri),
    }

    return (
        <>
            <Base className="header-wrap">
                <Center className="base-top">
                    <div className="base-top-logo" onClick={goHome}>
                        <Logo src={Language.details.logo} alt={Language.details.title}/>
                        <Dopamine src={DopamineImage} alt={Language.details.title} className="dopamine-logo" />
                    </div>

                    <div className="base-top-contact">
                        <div className="social-links">
                            {Language.socials.facebook ?
                                <a href={`https://www.facebook.com/${Language.socials.facebook}`} target="_blank"><FacebookIcon {...iconStyle} /></a> : null}
                            {socials.youtube ?
                                <a href={`${socials.youtube}`} target="_blank"><YoutubeIcon {...youTubeIconStyle} /></a> : null}

                            {socials.linkedIn ?
                                <a href={socials.linkedIn} target="_blank"><LinkedinIcon {...iconStyle} /></a> : null}

                            {socials.twitter ?
                                <a href={socials.twitter} target="_blank"><TwitterIcon {...iconStyle} /></a> : null}

                            {socials.whatsapp ?
                                <a href={socials.whatsapp} target="_blank"><WhatsappIcon {...iconStyle} /></a> : null}
                        </div>

                        <TrapezoidBox className="base-top-phone" style={{marginLeft:'auto'}}>
                            <p>{getValue(website_details.details.contact_us_phone)}</p>
                        </TrapezoidBox>

                        <img src={MenuIcon} className="menu-icon svg-fill-default" onClick={openSideMenu} />
                    </div>
                </Center>
            </Base>

            <Sticky topOffset={80} disableCompensation={true} >
                    {({
                        style,
                        isSticky
                    }) => (
                        <Base bgColor={`url(${NavPattern}) repeat-x`}
                              boxShadow={isSticky ? "0 2px 4px rgba(100,100,100,0.25)" : 'none'}
                              zIndex={7}
                              style={{height:NavHeight}}
                              className="nav-header"
                              >
                        <Base bgColor={`url(${NavPattern}) repeat-x`}
                              style={style}
                              zIndex={7}
                              >
                            <Nav jc="flex-start"
                                 padding="0px"
                                 className="nav-links">

                                {/* Navigation Link */}
                                {
                                    Language.navbar.map(
                                        (item, index) => {
                                            if(item.hideAfterLogin && state.user) return null;
                                            return (
                                                <ListItem key={String(index)}>
                                                    <NavLinkWrap
                                                        key={String(index)}
                                                        to={item.link} {...activeNavLink}>
                                                        {item.name}
                                                    </NavLinkWrap>

                                                    {item.child.length ?
                                                        <NavLinkSub className="sub-menu">
                                                            {
                                                                item.child.map(
                                                                    (child, childIndex) => {
                                                                        return (
                                                                            <NavLink
                                                                                onClick={onNavClick(child.link)}
                                                                                key={String(childIndex)}
                                                                                to={child.link === AUTH_KEYS.TOGGLE_AUTH_MODAL ? history.location.pathname : child.link}>
                                                                                {child.name}
                                                                            </NavLink>  
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </NavLinkSub> : null
                                                    }
                                                </ListItem>
                                            )
                                        }
                                    )
                                }

                                {state.user ?
                                <>

                                    <NavLinkWrap key="me" to={ROUTE.USER_SPACE} {...activeNavLink}>{Language.user_space.title}</NavLinkWrap>

                                    <TrapezoidBox2>
                                        <Text size="12pt" weight="bold" cursor="pointer" onClick={userCp}>{UserName(state.user.user)}</Text>
                                        &nbsp;&nbsp;
                                        <Button  color={themeConfigs.colors.text} width="130px" height="45px" radius="50px" onClick={logout} padding="10px">{Language.auth.logout}</Button>
                                    </TrapezoidBox2>
                                </>
                                : null }
                            </Nav>
                            </Base>
                        </Base>
                    )}
                </Sticky>
        </>
    )
}

const Logo = styled.img`
    width:114px;
    height:80px;
    margin-right:10px;
    cursor:pointer;
`;
const Dopamine = styled.img`
    width:262px;
    height:80px;
    margin-right:10px;
`;

const Nav = styled(Center)<CenterType>`
    background: url(${NavPattern}) repeat-x;
    height:${NavHeight}px;
    z-index:5;
`;

const ListItem = styled.li`
    list-style: none;
    position: relative;
    padding: 15px 0;
    
    @media (max-width: 992px) {
        display: flex;
        align-items: center;
    }
    
    &:hover {
        .sub-menu {
            display: flex;
        }
    }
`;

const NavLinkWrap = styled(NavLink)`
    min-width:100px;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    text-decoration:none;
    color:${p => p.theme.colors.text};
    font-weight:bold;
    font-size:14pt;
    padding:0 10px;
`;

const NavLinkSub = styled.div`
    position: absolute;
    min-width: 200px;
    max-width: 250px;
    background: ${p => p.theme.colors.default};
    border-radius: 7px;
    display: none;
    flex-direction: column;
    padding: 10px 0;
    top: 55px;
    margin: 0 auto;
    right: 50%;
    transform: translateX(50%);
    
    a {
        color: #fff;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        transition: all 250ms;
        
        @media (max-width: 992px) {
            color: ${p => p.theme.colors.default};
        }
        
        &:hover {
            background: rgba(0,0,0, .1);
            text-decoration: none;
        }
    }
    
    &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 2px;
        height: 0px;
        border-bottom: solid 10px ${p => p.theme.colors.default};
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
        
        @media (max-width: 992px) {
            display: none;
        }
    }
    
    @media (max-width: 992px) {
        display: inline-flex;
        flex-direction: row;
        background: transparent;
        position: static;
        transform: unset;
        margin-right: 25px;
    }
`;

const Trapezoid = styled.div`
    margin-left:auto;
    border-bottom: 80px solid ${p => p.theme.colors.default};
    border-left: 60px solid transparent;
    height: 0;
    width: 310px;
    display:flex;
    justify-content:center;
    position: relative;
`;

const PhoneTrapezoid = styled(Trapezoid)`
    align-items:center;
    height: auto;
    width: 230px;
    display: inline-block;
    vertical-align: top;
`;

const TrapezoidBox = styled.div`
    background-color: ${p => p.theme.colors.default};
    color: white;
    display: inline-block;
    position: relative;
    line-height: 4em;
    padding: 0 1em;
    vertical-align: top;

    &:before {
        content: '';
        border: 2em solid transparent;
        border-right-color: ${p => p.theme.colors.default};
        border-bottom-color: ${p => p.theme.colors.default};
        width: 0px;
        height: 0px;
        position: absolute;
        right: 100%;
        top: 0;
    }
`;
const TrapezoidBox2 = styled.div`
    background-color: ${p => p.theme.colors.default};
    color: white;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
    height: 80px;
    width: 255px;
    margin-left: auto;
    vertical-align: top;

    &:before {
        content: '';
        border: 2.5em solid transparent;
        border-right-color: ${p => p.theme.colors.default};
        border-bottom-color: ${p => p.theme.colors.default};
        width: 0px;
        height: 0px;
        position: absolute;
        right: 100%;
        top: 0;
    }

    
    p {
        color: #fff;
        @media (max-width: 1024px) {
            color: ${p => p.theme.colors.default} !important;
        }
    }

    > div {
        background: #fff;
        
        p {
            color: ${p => p.theme.colors.default};
            @media (max-width: 1024px) {
                color: #fff !important;
            }
        }

        @media (max-width: 1024px) {
            background: ${p => p.theme.colors.default} !important;
            color: #fff !important;
        }
    }
`;

export default Header
