import React from 'react';
import {emit} from 'jetemit'
import {state} from 'jetstate'
import AUTH_KEYS from 'vars/auth';
import * as Sentry from '@sentry/browser';

function Logout(){
    window.localStorage.removeItem(AUTH_KEYS.USER_LOCALSTROAGE_NAME);
    state.user = null;
    emit(AUTH_KEYS.USER_UPDATE);

    Sentry.configureScope(scope => scope.setUser(null));
}

export default Logout;