import { useState, useCallback } from 'react';
import {login, IErrorLogin, initialErrorsLogin} from '../model'
import ApiCall from 'utils/fetch';
import { ValidationError } from 'helper/fetchValidationError';
import { setUser } from 'helper/user';
import isMobilePhone from 'validator/lib/isMobilePhone';
import Language from 'lang';
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom';

function useLoginLogic () {
    const [loading , setLoading] = useState(false);
    const [getVerifyCode , setGetVerifyCode] = useState(false);
    const [errors, setErrors] = useState<IErrorLogin>(initialErrorsLogin)
    const [fetchErrors, setFetchErrors] = useState<object[]|any>([])
    let history = useHistory();
    const alert = useAlert();
    function validate(name:any , text:any , justReturn:boolean = false){
        const validate_errors:any = {};
        if(justReturn) return validate_errors;
        setErrors({...errors,[name]:typeof text === "string" && text === "",...validate_errors});
        // console.log("validate ",name," => " ,{...errors,[name]:typeof text === "string" && text === "",...validate_errors})
    }

    const onChange = (name: any , options:any = {}) => (text: any) => {
        let value = text.nativeEvent ? text.nativeEvent.text.toLowerCase() : text;
        // validate
        if(options.validate) validate(options.sub ? options.sub : name , value , false);

        login[name] = value;
    }

    async function submitLogin(){
        if(loading) return;
        // console.log("errors =>", errors)
        if(Object.values(errors).every(e => !e)){
            setFetchErrors([]);
            setLoading(true);
            if(getVerifyCode){
                let response = await ApiCall(`/user/verify?code=?email=${login.email}&code=${login.code}`,{get:true,log:true,data:login , justData:true , noError:true , status:200 , error_status:[404,401,400] });
                if(response.token) {
                    setGetVerifyCode(false);
                    setUser(response);
                    history.push("/")
                }
            }else{
                let sendData = isMobilePhone(login.email) ? {mobileNumber:login.email,password:login.password,website:Language.details.website_type_key,} : login;
                let response = await ApiCall("/user/login",{post:true,log:true,data:sendData , justData:true , noError:true , status:200 , error_status:[404,401,400,403]});
                if(response.token){
                    setUser(response);
                    setGetVerifyCode(false);
                }else if(response.Message && typeof response.Message === "string"){
                    if(response.Message.startsWith("Verification")){
                        setErrors({...errors,['code']:true});
                        setGetVerifyCode(true);
                    }
                }else if(response.hasError){
                    if(response.status && response.status === 404){
                        setFetchErrors([{name:"",error:Language.messages.wrong_email_or_password}]);
                    }else if(response.data.error){
                        setFetchErrors([{name:"",error:response.data.error}]);
                    }else if(response.data.Message && typeof response.data.Message === "string"){
                        if(response.data.Message.endsWith("Not Active")){ // account is not verified (email)
                            setFetchErrors([{name:"",error:Language.auth.not_verify_email}]);
                        }
                    }else{
                        setFetchErrors(ValidationError(response.data));
                    }
                    // alert.error(Language.messages[getVerifyCode ? 'wrong_code' : 'wrong_email_or_password'],{timeout: 3000});
                }
            }
            
            setLoading(false);
        }
    }

    function resetLogin(){
        setErrors(initialErrorsLogin)
        setGetVerifyCode(false);
        setFetchErrors([]);
        setLoading(false);
    }

    return {
        onChange,
        submitLogin,
        loading,
        errors,
        fetchErrors,
        getVerifyCode,
        resetLogin,
        setGetVerifyCode,
        setErrors
    }
}

export default useLoginLogic;