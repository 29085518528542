import React, { useState, useEffect } from 'react'
import BurgerMenu from 'react-burger-menu';
import './style.css'
import Language from 'lang';
import {state} from 'jetstate'
import SideMenuItem from './components/SideMenuItem';
import ROUTE from 'vars/router';
import { on , unsubscribeOf} from 'jetemit';
import Text from 'styles/Text';
import { themeConfigs } from 'App';
import Button from 'components/Button';
import Logout from 'helper/logout';
import { useAlert } from 'react-alert'
import UserName from "../../utils/userName";

export default function SlideMenu() {
    const [isOpen, setIsOpen] = useState(false);
    const Menu = BurgerMenu['slide'];
    const alert = useAlert();

    const out = () => {
        setIsOpen(false);
        Logout();
        alert.success(Language.messages.signout,{timeout: 3000});
    }
    
    useEffect(() => {

        // event listener for open auth modal
        on(ROUTE.SIDE_MENU , () => {
            setIsOpen(true);
        });
        
        // return () => {
        //     unsubscribeOf(ROUTE.SIDE_MENU);
        // };

    }, []);
    
    const onClick = () => setIsOpen(false);

    function handleStateChange(state){
        setIsOpen(state.isOpen)
    }

    return (
        <Menu isOpen={isOpen} right pageWrapId='main' outerContainerId="outer-container" onStateChange={(state) => handleStateChange(state)}>
            {state.user ? 
            <div className="user-info-side">
                <Text size="12pt" weight="bold" cursor="pointer" onClick={null} align="center">{UserName(state.user.user)}</Text>
                <Button bgColor={themeConfigs.colors.default} color="white" width="130px" height="45px" radius="50px" onClick={out} padding="2px" align="center">{Language.auth.logout}</Button>
            </div>
            : null}

            {
                Language.navbar.map(
                    (item, index) => {
                        if(item.hideAfterLogin && state.user) return null;
                        return <SideMenuItem item={item} onPress={onClick}  key={String(index)} />
                    }
                )
            }

        {state.user ? 
            <SideMenuItem item={{link:ROUTE.USER_SPACE,name:Language.user_space.title,child:[]}} onPress={onClick}  key="login" />
        : null}
        </Menu>
    )
}