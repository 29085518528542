import styled from "styled-components";

export const LoadingWrap = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
`;

export const Button = styled.div`
    width:100%;
    height:50px;
    display:flex;
    justify-content:center;
    align-items:center;
    background:${p => p.theme.colors.gunmetal_two};
    border-radius:30px;
    cursor:pointer;
`;
