import React from 'react'
import {IconTypes} from 'types'
export default function WhatsappIcon(props:IconTypes) {
    return (
        <svg style={props.style} xmlns="http://www.w3.org/2000/svg" width={props.width || "40"} height={props.height || "40"} viewBox="0 0 34.194 34.194">
            <g id="whatsapp" className="svg-logo svg-fill-default">
                <g id="Group_103" data-name="Group 103">
                    <path  id="Path_70" d="M17.1 0h-.009A17.086 17.086 0 0 0 3.255 27.118l-2.131 6.351 6.572-2.1A17.094 17.094 0 1 0 17.1 0zm9.948 24.143c-.412 1.165-2.049 2.131-3.355 2.413-.893.19-2.06.342-5.988-1.287-5.024-2.082-8.26-7.187-8.512-7.518a9.765 9.765 0 0 1-2.03-5.157 5.458 5.458 0 0 1 1.749-4.161 2.484 2.484 0 0 1 1.748-.613c.212 0 .4.011.573.019.5.021.754.051 1.086.844.412.994 1.417 3.447 1.537 3.7a1.018 1.018 0 0 1 .073.925 2.955 2.955 0 0 1-.554.784c-.252.291-.492.513-.744.825-.231.271-.492.562-.2 1.064a15.175 15.175 0 0 0 2.769 3.448 12.57 12.57 0 0 0 4.013 2.471 1.081 1.081 0 0 0 1.205-.19 20.686 20.686 0 0 0 1.336-1.77.954.954 0 0 1 1.227-.372c.462.16 2.9 1.368 3.407 1.618s.833.372.955.583a4.258 4.258 0 0 1-.294 2.374z" data-name="Path 70"/>
                </g>
            </g>
        </svg>
    )
}
