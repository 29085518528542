import React from 'react'
import { themeConfigs } from 'App'

export default function Top() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="192" >
            <g id="Artboard_1" data-name="Artboard – 1" clipPath="url(#clip-Artboard_1)">
                <rect width="1920" height="192" fill="#fff"/>
                <g id="Group_166" data-name="Group 166" transform="translate(370 426)">
                <path id="Path_2" data-name="Path 2" d="M0,0H1920l.023,819.887L0,938.772Z" transform="translate(1550.023 512.455) rotate(180)" fill={themeConfigs.colors.secondary}/>
                <path id="Path_1" data-name="Path 1" d="M0,0H1920l.023,966.864L0,854.456Z" transform="translate(1550.023 619.455) rotate(180)" fill={themeConfigs.colors.primary}/>
                </g>
            </g>
        </svg>
    )
}
